import React, {useEffect, useState} from 'react';
import {Location} from '@reach/router';
import {Container, Col, Row, Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons/faCartPlus';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {useUserState} from 'contexts/user';
import {usePhaseState} from 'funnel-schedule/phase-context';
import track from 'utils/track';
import useBeacon from 'utils/useBeacon';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import {SectionTriangle} from 'components/Elements/Section';
import Webinar from 'components/Webinar';
import Video from 'components/Video';
import CheckoutButton from 'components/CheckoutButton';
import {SalesDisclaimer} from 'components/SalesCta';
import SalesCopy from 'components/SalesCopy';
import RegistrationModal from 'components/RegistrationModal';
import ModalExitScreening from 'components/ModalExitScreening';
import ShareButtons from 'components/ShareButtons';
import Footer from 'components/Footer';

const COMPLETED_MASTERCLASS_EVENT = 'Completed Watching Masterclass';

export default function (props) {
  return <Location>{({location}) => <ScreeningIndex location={location} {...props} />}</Location>;
}

export function ScreeningIndex({variant = 'default', replayOverride = false, location = {}, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const phase = webinarSchedule.phase;
  const {registrationId, bucket, events = []} = useUserState();
  const [anchorScrolled, setAnchorScrolled] = useState(false);
  const hasCompletedMasterclass = events.includes(COMPLETED_MASTERCLASS_EVENT);
  const delayReveal =
    (events.includes(COMPLETED_MASTERCLASS_EVENT) && !replayOverride && phase != 'before') ||
    phase === 'replay' ||
    phase === 'after';
  const beforeWebinar = phase === 'before' && !replayOverride;
  const includeBlogArticle = false;

  const productId = 'C-PPT';
  const productSku = 'C-PPT';
  const productName = 'Plant-Powered & Thriving';
  const productSlug = 'ppt-masterclass-wlc-30daytrial';
  const productValue = 247;
  const productPaymentPlanValue = 89;

  useEffect(() => {
    if (hasCompletedMasterclass)
      track('Product Viewed', {
        product_id: productId,
        sku: productSku,
        name: productName,
        variant: productSlug,
        value: productValue,
        currency: 'usd'
      });
  }, [hasCompletedMasterclass]);

  useEffect(() => {
    if (anchorScrolled) return;
    if (events.includes(COMPLETED_MASTERCLASS_EVENT)) {
      setAnchorScrolled(true);
      if (window !== undefined && window.location.hash === '#join') {
        if (document.getElementById('purchase')) {
          document.getElementById('purchase').scrollIntoView();
        }
      }
    }
  }, [events, anchorScrolled]);

  function CtaOffer({iconColor = 'green', btnText = 'Buy Now'}) {
    return (
      <>
        <h3 className="section-heading mb-2">
          Regular price: <del>$497</del>
        </h3>
        <h2 className="text-h1 text-green mt-0 mb-3">Now Only: $247</h2>
        <p>
          (Plus, get the 20-presentation Ultimate Health Collection for{' '}
          <b>
            <i>FREE</i>
          </b>
          )
        </p>
        <CheckoutButton className="offer-box-button" sku={productSku} price={productValue} slug={productSlug}>
          <FontAwesomeIcon
            className="mr-2"
            // @ts-ignore
            icon={faCartPlus}
          />{' '}
          {btnText}
        </CheckoutButton>
        <SalesDisclaimer iconColor={iconColor} />
        <p className="mt-4 mb-1">Prefer a 3-month payment plan? We’ve got you covered!</p>
        <CheckoutButton
          color="link"
          className="btn-link-checkout"
          sku={productSku}
          price={productPaymentPlanValue}
          slug={productSlug}
          queryParams={{opt: 1}}
        >
          Join now for just ${productPaymentPlanValue}/month!
        </CheckoutButton>
      </>
    );
  }

  useBalanceText();
  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title="Join the Food For Health Masterclass with John Robbins" />

      <div className="page-content">
        <SectionTriangle id="" color="white" type="bottom" className="section-triangle-first">
          <Container>
            <Row className="text-center">
              <Col>
                <p>
                  <img
                    className="mb-3"
                    src="https://cdn.foodrevolution.org/ppt/ppt-masterclass-logo-purpleblack.svg"
                    alt="Food For Health Masterclass logo"
                    style={{maxHeight: '75px'}}
                  />
                </p>
                <p className="balance-text">
                  In this <b>FREE Masterclass,</b> John Robbins reveals 10 mighty, plant-powered breakthroughs that can
                  unlock your unique body’s{' '}
                  <b>
                    <i>ultimate health potential.</i>
                  </b>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col lg="10" className="video-col px-0 px-sm-3">
                {replayOverride ? (
                  <Video
                    url="https://customer-ros8xhsmbkksflna.cloudflarestream.com/b8d158b8aab062ee7c7692a94edb8499/watch"
                    label="PPT - Masterclass"
                  />
                ) : (
                  <Webinar noun="Masterclass" label="PPT - Masterclass" />
                )}
                <div className="background-purple p-3 text-center text-white">
                  <p className="balance-text m-0">
                    <b>IMPORTANT:</b> Stay tuned till the end for an amazing story from John Robbins (that he’s never
                    shared publicly before!).
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="text-center mt-5">
              <Col>
                <h4 className="section-heading text-400 mb-0">
                  We are{' '}
                  <b>
                    <i>so excited</i>
                  </b>{' '}
                  you are here. Thank you for taking the time to join us.
                </h4>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle
          id=""
          color="light-gray"
          type="bottom"
          className={classNames(`text-center`)}
          offset={replayOverride ? 'bottom' : 'none'}
        >
          <Container>
            <Row>
              <Col>
                {beforeWebinar && (
                  <h4 className="section-heading text-400">
                    While we are waiting to get started — <b>here is what you can do.</b>
                  </h4>
                )}
                <h3 className={classNames(`text-purple`, beforeWebinar ? 'mt-5' : 'section-heading')}>
                  {beforeWebinar && includeBlogArticle ? <>1. </> : null}Download your FREE Workbook
                </h3>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/ppt/food-for-health-masterclass-workbook.png"
                  alt="food for health masterclass workbook cover"
                  style={{maxHeight: '200px'}}
                />
                <p className="balance-text mb-4">
                  Use this workbook to get the MOST out of the <i>Food For Health Masterclass.</i>
                </p>
                <p>
                  <Button
                    color="cta"
                    href="https://cdn.foodrevolution.org/ppt/Food%20For%20Health%20MASTERCLASS%20with%20John%20Robbins%20Private%20Workbook.pdf"
                    className=""
                    target="_blank"
                    onClick={() => {
                      track('Button Clicked', {
                        label: 'Download My Workbook',
                        url: 'https://cdn.foodrevolution.org/ppt/Food%20For%20Health%20MASTERCLASS%20with%20John%20Robbins%20Private%20Workbook.pdf'
                      });
                    }}
                  >
                    Download My Workbook
                  </Button>
                </p>
                {beforeWebinar && includeBlogArticle ? (
                  <>
                    <h3 className="text-purple">
                      2. Read this blog article (with recipes!) on Healthy Morning Drinks to Start Your Day Off Right
                    </h3>
                    <img
                      className="box-shadow-black-25 mb-4"
                      src="https://cdn.foodrevolution.org/ppt/images/1185840253.jpg"
                      alt="woman holding green smoothie"
                      style={{maxHeight: '300px'}}
                    />

                    <p className="balance-text mb-4">
                      For some people, starting the day with anything other than coffee is unimaginable. But not
                      everyone tolerates caffeine. And many other beverage options provide their own special benefits
                      for health, mood, and mental clarity. In this article, you’ll discover other healthy morning
                      beverages and why you might want to include them in your morning routine.
                    </p>
                    <p>
                      <Button
                        color="cta"
                        href="https://foodrevolution.org/blog/healthy-morning-drinks/"
                        target="_blank"
                        onClick={() => {
                          track('Button Clicked', {
                            label: 'Read the Article',
                            url: 'https://foodrevolution.org/blog/healthy-morning-drinks/'
                          });
                        }}
                      >
                        Read the Article
                      </Button>
                    </p>
                    <h2 className="mb-2">We will see you soon!</h2>
                  </>
                ) : null}
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle
          id=""
          color="white"
          className={classNames(
            `text-center`,
            (phase != 'before' && delayReveal) || replayOverride ? '' : 'section-triangle-last'
          )}
          offset={(phase != 'before' && delayReveal) || replayOverride ? 'bottom' : 'none'}
          type={delayReveal ? 'bottom' : 'none'}
        >
          <Container>
            <Row>
              <Col>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  alt="Food Revolution Network logo"
                  width={300}
                  height={126}
                  style={{maxHeight: '200px'}}
                />
                <p className="balance-text">
                  Food Revolution Network’s mission is to inspire and advocate for healthy, ethical, and sustainable
                  food for all through education about plant-powered foods.
                </p>
                <p className="balance-text">
                  Guided by John and Ocean Robbins, with more than a million members and with the collaboration of many
                  of the top food revolutionary leaders of our times, FRN aims to change the way the world eats.
                </p>
                <p className="balance-text">
                  As a certified B Corp, we commit to these values in every aspect of our work.
                </p>
                <img
                  src="https://cdn.foodrevolution.org/global/b-corp-logo-black.svg"
                  alt="B Corp Logo"
                  width={103}
                  height={150}
                  style={{maxHeight: '200px'}}
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        {delayReveal && (
          <>
            <SectionTriangle id="" color="purple" type="bottom" className="text-center text-white">
              <Container>
                <Row>
                  <Col>
                    <h5 className="section-only-heading">
                      As a thank you for attending this Masterclass, you’ve unlocked a special 50% enrollment discount
                      for your spot in John & Ocean Robbins’ life-changing course, Plant-Powered & Thriving.
                    </h5>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="purchase" color="white" type="bottom">
              <Container>
                <Row className="text-center">
                  <Col>
                    <p>
                      <b>
                        <i>This special enrollment discount is ONLY available on this page.</i>
                      </b>
                    </p>
                    <p>
                      Now that you know what’s at stake, it’s time to take action! You can enjoy more energy, better
                      health, and fewer aches and pains when you are plant-powered & thriving!
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                  <Col lg="10">
                    <div className="cta-box-screening text-center rounded box-shadow-black-50">
                      <div className="background-purple text-white rounded-top p-3">
                        <h5 className="section-only-heading">
                          Enroll Today, and You'll Get Your 50% Discount + Get the Ultimate Health Collection for FREE!
                        </h5>
                      </div>
                      <div className="background-light-gray rounded-bottom p-4 p-lg-5">
                        <img
                          className="mb-4"
                          src="https://cdn.foodrevolution.org/ppt/ppt-logo.svg"
                          alt="Plant-Powered & Thriving logo"
                          style={{maxHeight: '100px'}}
                        />
                        <img
                          className="mb-4"
                          src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png"
                          alt="Plant-Powered & Thriving course product image"
                          style={{maxHeight: '250px'}}
                        />
                        <p>
                          Join John & Ocean Robbins & Chef Cynthia Louise for a seven-module course that will give you{' '}
                          <b>science-backed strategies</b> and <b>skills</b> to help you achieve your{' '}
                          <b>
                            <i>healthiest</i>
                          </b>{' '}
                          and{' '}
                          <b>
                            <i>best life.</i>
                          </b>
                        </p>
                        <CtaOffer />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="screening-imagine" color="black" type="bottom" className="text-center text-white">
              <Container>
                <Row>
                  <Col>
                    <p className="balance-text">
                      Imagine what it will be like to be{' '}
                      <b>
                        <i>excited about your meals</i>
                      </b>{' '}
                      and confident that friends and family will enjoy your food as much as you do. Imagine spending{' '}
                      <b>
                        <i>less time planning</i>
                      </b>{' '}
                      and stressing about meals and more time doing the things you love, including time with friends and
                      family, sleep, exercise, gardening, and preparing{' '}
                      <b>
                        <i>meals you actually enjoy…</i>
                      </b>
                    </p>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SalesCopy version="screening" variant={variant}>
              <>
                <div className="cta-headline text-white text-center">
                  <h3 className="section-heading text-400 mb-5">
                    Take Charge of Your Health with{' '}
                    <b>
                      <i>Plant-Powered & Thriving</i>
                    </b>
                  </h3>
                </div>
                <div className="cta-box background-light-gray rounded box-shadow-black-50 text-center p-4 p-lg-5">
                  <img
                    className="mb-4"
                    src="https://cdn.foodrevolution.org/ppt/ppt-product-image.png"
                    alt="Plant-Powered & Thriving course product image"
                    style={{maxHeight: '250px'}}
                  />
                  <h4 className="section-heading text-400">
                    Get instant and unlimited access — <i>guaranteed!</i>
                  </h4>
                  <CtaOffer {...props} />
                </div>
                <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
                  <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                    <h6 className="section-only-heading text-center text-lg-left text-400">
                      For every new order, we make a donation to Trees for the Future, enabling them to plant another
                      organic fruit or nut tree in a low-income community.
                    </h6>
                  </Col>
                  <Col xs="11" lg="3" className="text-center">
                    <img
                      className="logo-trees"
                      alt="Trees for the Future logo"
                      src="https://cdn.foodrevolution.org/global/trees-logo-white.png"
                      width={1080}
                      height={442}
                      style={{maxHeight: '100px', width: 'auto'}}
                    />
                  </Col>
                </Row>
              </>
            </SalesCopy>
          </>
        )}

        {replayOverride ? (
          <SectionTriangle
            id="replay-cta"
            color="gradient-indigo-purple-right"
            innerClass="text-center text-white section-webinar-inner-cta"
            type="top"
          >
            <Container>
              <Row>
                <Col>
                  <h4 className="section-heading">
                    Are you interested in enrolling in John & Ocean Robbins’ course, Plant-Powered & Thriving?
                  </h4>
                  <h5>Click below to learn more:</h5>
                  <Button className="btn-join btn-lg" color="cta" href="/join/" target="_blank">
                    Join Now!
                  </Button>
                </Col>
              </Row>
            </Container>
          </SectionTriangle>
        ) : (
          <>
            <RegistrationModal />
            {phase != 'before' && !replayOverride ? <ModalExitScreening /> : null}
          </>
        )}
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>
    </div>
  );
}
